import { render, staticRenderFns } from "./WRadio.vue?vue&type=template&id=994d773a&scoped=true&"
import script from "./WRadio.vue?vue&type=script&lang=js&"
export * from "./WRadio.vue?vue&type=script&lang=js&"
import style0 from "./WRadio.vue?vue&type=style&index=0&id=994d773a&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "994d773a",
  null
  
)

export default component.exports