<template>
  <div class="radio-wrapper"
    :class="[sizeClass]"
  >
    <label :for="id" class="label">
      <input
        class="w-radio"
        type="radio"
        :id="id"
        :name="name"
        :value="value"
        :checked="checked"
        :disabled="disabled"
        :required="required"
        :class="[isErrorClass, customClass]"
        :style="customStyle"
        @input="handleInput"
        @change="handleChange"
        v-bind="$attrs"
      />
      <span v-if="label">{{ label }}</span>
    </label>
    <span v-show="errorText" class="error-tooltip is-error">
      {{ errorText }}
    </span>
  </div>
</template>

<script>
/**
* UI-компонент radio.
* Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
*
* @typedef {Object} WRadioProps
* @prop {string|number} value - Значение radio. Для использования компонента с v-model.
* @prop {string} [name=''] - Имя radio.
* @prop {string} id  - ID.
* @prop {string} [label=''] - Label для radio. Если не задать, label не будет.
* @prop {string|Object} [customClass=''] - Кастомные классы для radio. Если вдруг нужно задать нестандартный класс.
* @prop {Object} [customStyle={}] - Кастомные стили для radio. Если вдруг нужно задать нестандартные inline-свойства.
* @prop {boolean} [required=false] - Флаг для обязательного выбора radio.
* @prop {boolean} [disabled=false] - Флаг для отключения radio.
* @prop {boolean} [checked=false] - Флаг для выбора radio по умолчанию.
*
* Размеры:
* @prop {boolean} [sm=false] - Флаг для размера 'sm'.
* @prop {boolean} [md=true] - Флаг для размера 'md'.
* @prop {boolean} [lg=false] - Флаг для размера 'lg'.
*
* Валидация:
* @prop {string} [errorText=''] - Текст ошибки для textarea.
*
* События:
* @event input - Срабатывает при изменении значения radio.
* @event change - Срабатывает при изменении значения и потере фокуса radio.
*
* Примеры использования компонента WRadio:
*
* @example
* Пример 1: Базовый radio
* <WRadio v-model="inputValue" />
*
* @example
* Пример 2: Radio с label и ID
* <WRadio value="Moscow" @input="handleRadio" label="Radio 1" id="radio1" />
*
* @example
* Пример 3: Radio с пользовательскими классами и стилями
* <WRadio value="Moscow" @input="handleRadio" customClass="my-custom-class" :customStyle="{ color: 'blue', border: '1px solid blue' }" />
*
* @example
* Пример 4: Отключенный radio
* <WRadio value="Moscow" @input="handleRadio" :disabled="isInputDisabled" />
*
* @example
* Пример 5: Выбранный radio по умолчанию
* <WRadio value="Moscow" @input="handleRadio" checked />
*
* @example
* Пример 6: Обязательный выбор radio
* <WRadio value="Moscow" @input="handleRadio" required />
*
* @example
* Пример 7: Radio с размером 'lg'
* <WRadio value="Moscow" @input="handleRadio" lg />
*/

export default {
  name: 'WRadio',
  inheritAttrs: false, // Отключаем наследование атрибутов по умолчанию
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    customClass: {
      type: [String, Object],
      default: ''
    },
    customStyle: {
      type: Object
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    // sizes
    sm: {
      type: Boolean,
      default: false
    },
    md: {
      type: Boolean,
      default: true
    },
    lg: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isErrorClass() {
      return {
        'is-error': this.errorText
      };
    },
    sizeClass() {
      if (this.sm) {
        return 'sm';
      }

      if (this.md) {
        return 'md';
      }

      if (this.lg) {
        return 'lg';
      }

      return '';
    }
  },
  methods: {
    async handleInput(event) {
      const targetValue = event.target.value;
      this.$emit('input', targetValue);
    },
    async handleChange(event) {
      this.$emit('change', event.target.value);
    }
  }
};
</script>

<style lang="css" scoped>
.radio-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* root input class */
.w-radio {
  display: block;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  cursor: pointer;
}

.w-radio:disabled {
  background-color: var(--gray-lighter);
  cursor: not-allowed;
}

/* label */
.label {
  display: flex;
  align-items: center;
  color: var(--label-font-color);
  line-height: 1.25;
  margin: 0;
  cursor: pointer;
}

.label>span{
  display: inline-block;
  margin-left: 1rem;
}

/* size classes */
.sm {
  font-size: 0.875rem;
}
.md {
  font-size: 0.875rem;
}
.lg {
  font-size: 1.25rem;
}

.lg .w-radio {
  width: 1rem;
  height: 1rem;
}

/* error tooltip */
.error-tooltip {
  display: block;
  font-size: 0.85rem;
  margin-top: 5px;
}

.is-error {
  color: var(--brand-danger);
  border-color: var(--brand-danger)
}
</style>
