<template>
  <div class="w-checkbox-wrapper">
    <div class="input-wrapper" :class="[isDisabledClass, withLabelClass]">
      <input
        ref="input"
        class="input"
        type="checkbox"
        v-bind="$attrs"
        :id="id"
        :name="name"
        :class="customClass"
        :style="customStyle"
        :checked="checked"
        @change="handleChange"
        @input="handleInput"
        @click="handleClick"
        :disabled="disabled"
      />
      <label :for="id" class="label view-type-default">
        {{ label }}
      </label>
    </div>
    <span v-show="errorText" class="error-tooltip is-error">
      {{ errorText }}
    </span>
  </div>
</template>

<script>
/**
* UI-компонент checkbox.
* Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
*
* @typedef {Object} WCheckboxProps
* @prop {string} id - Уникальный идентификатор для чекбокса.
* @prop {string} [name=''] - Имя чекбокса.
* @prop {boolean} [checked=false] - Устанавливает начальное состояние чекбокса.
* @prop {string} [label=''] - Label для чекбокса. Если не задать, label не будет отображаться.
* @prop {string|Object} [customClass=''] - Кастомные классы для чекбокса.
* @prop {Object} [customStyle={}] - Кастомные стили для чекбокса.
* @prop {boolean} [disabled=false] - Флаг для отключения чекбокса.
* @prop {string} [errorText=''] - Текст ошибки для чекбокса.
*
* Валидация:
* @prop {string} [errorText=''] - Текст ошибки.
*
* События:
* @event change - Срабатывает при изменении значения чекбокса и потере фокуса.
* @event input - Срабатывает при изменении значения чекбокса.
* @event click - Срабатывает при клике на чекбокс.
*
* Примеры использования компонента WCheckbox:
*
* @example
* Пример 1: Базовый checkbox
* <WCheckbox id="checkboxId" v-model="isChecked" />
*
* @example
* Пример 2: Checkbox с меткой и именем
* <WCheckbox id="checkboxId" v-model="isChecked" name="myCheckbox" label="Моя метка" />
*
* @example
* Пример 3: Checkbox с пользовательскими классами и стилями
* <WCheckbox id="checkboxId" v-model="isChecked" customClass="my-custom-class" :customStyle="{ color: 'blue', border: '1px solid blue' }" />
*
* @example
* Пример 4: Отключенный checkbox
* <WCheckbox id="checkboxId" v-model="isChecked" :disabled="isDisabled" />
*
* @example
* Пример 5: Checkbox с обработчиком изменения значения
* <WCheckbox id="checkboxId" v-model="isChecked" @change="handleChange" />
*/

export default {
  name: 'WCheckbox',
  // https://vee-validate.logaretm.com/v2/concepts/components.html#component-constructor-options
  $_veeValidate: {
    // value getter
    value() {
      const value = this.$refs.input.checked;

      // не знаю почему, но валидировать vee-validate 2.1.3 чекбоксы может только с такими значениями
      return value ? ['on'] : undefined;
    },
    // name getter
    name() {
      return this.name;
    }
  },
  inheritAttrs: false, // Отключаем наследование атрибутов по умолчанию
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    customClass: {
      type: [String, Object],
      default: ''
    },
    customStyle: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    isErrorClass() {
      return {
        'is-error': this.errorText
      };
    },
    isDisabledClass() {
      return this.disabled && 'disabled';
    },
    withLabelClass() {
      return this.label && 'with-label';
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('change', event);
    },
    handleInput(event) {
      this.$emit('input', event.target.checked);
    },
    handleClick(event) {
      this.$emit('click', event);
    }
  }
};
</script>

<style lang="css" scoped>
.w-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-wrapper {
  position: relative;
  min-height: 1.5rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.input-wrapper.with-label {
  padding-left: 1.5rem;
}
/* root input class */

.input {
  /* display: none; */
  position: absolute;
    z-index: -1;
    opacity: 0;
}

.label {
  margin-bottom: 0;
  cursor: pointer;
  line-height: 1.5;
}

.label::before {
    border-radius: 0.25rem;
    transition: 0.2s ease-in-out;
}

.label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    transition: 0.2s ease-in-out;
}

.input:checked~.label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.disabled .label {
    cursor: not-allowed;
}

/* view type
  он один по умолчанию, поэтому только один класс
*/
.label.view-type-default {
  color: var(--font-color);
}

.label.view-type-default::before {
  background-color: var(--checkbox-bg-color-default);
  border: 1px solid var(--checkbox-border-color-default);
}

.input:checked~.label.view-type-default::before {
    background-color: var(--checkbox-bg-color-checked);
    border: 1px solid var(--checkbox-border-color-checked);
}

.disabled .label.view-type-default::before {
    background-color: var(--checkbox-bg-color-disabled);
}

/* error tooltip */
.error-tooltip {
  display: block;
  font-size: 0.85rem;
  margin-top: 5px;
}

.is-error {
  color: var(--brand-danger);
  border-color: var(--brand-danger)
}
</style>
