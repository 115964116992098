<template>
  <div class="wrapper">
    <label v-if="label" :for="name" class="label">
      {{ label }}
    </label>
    <date-picker
      class="float-right"
      :class="[isErrorClass, customClass]"
      :value="dateRange"
      :datepickerForceSetValue="datepickerForceSetValue"
      :id="id"
      :name="name"
      :disabled="disabled"
      @focus="handleFocus"
      @open="$emit('open')"
      @confirm="$emit('confirm')"
      @clear="$emit('clear')"
      @close="$emit('close')"
      @input="$emit('input', $event)"
      @change="$emit('change')"
      v-bind="$attrs"
    />
    <span v-show="errorText" class="error-tooltip is-error">
      {{ errorText }}
    </span>
  </div>
</template>

<script>
/**
* UI-компонент для ввода даты и времени.
* Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
*
* @typedef {Object} WDatetimeInputProps
* @prop {Object} value - Значение input в виде объекта { startDate: number, endDate: number }.
* @prop {string} id  - ID.
* @prop {string} [name=''] - Имя input.
* @prop {string} [label=''] - Label для input. Если не задать, label не будет.
* @prop {string|Object} [customClass=''] - Кастомные классы для input. Если вдруг нужно задать нестандартный класс.
* @prop {Object} [customStyle={}] - Кастомные стили для input. Если вдруг нужно задать нестандартные inline-свойства.
* @prop {boolean} [disabled=false] - Флаг для отключения input.
* @prop { startDate: number, endDate: number } datepickerForceSetValue - Костыльный пропс, чтобы задать нужное значение datepicker извне. Подробнее см. компонент date-picker.vue
*
* Валидация:
* @prop {string} [errorText=''] - Текст ошибки для textarea.
*
* Больше пропсов для date-picker в самом компоненте date-picker. Их можно прокидывать при вызове, они приаттачатся к date-picker:
*@example
*<WDatetimeInput
  clearable
  :disabledDate="setDisabledDatepickerDatetime"
  :disabledTime="setDisabledDatepickerDatetime"
  />
*
* События:
* @event input - Срабатывает при изменении значения инпута.
* @event focus - Срабатывает при фокусировке на инпуте.
* @event open - Срабатывает при открытии datepicker.
* @event confirm - Срабатывает при подтверждении выбранной даты в datepicker.
* @event clear - Срабатывает при очистке выбранной даты в datepicker.
* @event close - Срабатывает при закрытии datepicker.
*
* Примеры использования компонента WDatetimeInput:
*
* @example
* <WDatetimeInput v-model="dateObject" />
*
* @example
* <WDatetimeInput v-model="dateObject" name="datePicker" label="Choose Date:" />
*
* @example
* <WDatetimeInput v-model="dateObject" customClass="custom-datepicker" :customStyle="{ color: 'blue', border: '1px solid blue' }" />
*
* @example
* <WDatetimeInput v-model="dateObject" :disabled="isDatePickerDisabled" />
*
* Для валидации datepicker удобнее всего применять скрытый инпут вместе с компонентом:
* @example
* <WDatetimeInput
    name="customDatetimeRange"
    :label="`${$t('reactiveLogs.generateLogsForCustomDatetime')}:`"
    v-model="customDatetimeRangeInputs"
    clearable
    :disabledDate="setDisabledDatepickerDatetime"
    :disabledTime="setDisabledDatepickerDatetime"
    :errorText="errors.first('customDatetimePicker-validation')"
  />
  <!-- скрытый инпут для валидации datepicker"-->
  <input
    style="display: none;"
    v-model.number="customDatetimeRangeInputs.endDate"
    type="number"
    name="customDatetimePicker-validation"
    v-validate="{ required: isValidateCustomDatetimeRangeDatepicker }"
    data-vv-validate-on="input"
    :data-vv-as="$t('reactiveLogs.interval')"
  />
*/

import DatePicker from '@/components/date-picker.vue';

export default {
  name: 'WDatetimeInput',
  inject: ['$validator'],
  inheritAttrs: false, // Отключаем наследование атрибутов по умолчанию
  components: {
    DatePicker
  },
  props: {
    value: {
      type: Object,
      required: true,
      validator: value => {
        const errorMessage = 'prop value must be typed as { startDate: number, endDate: number }';

        if (typeof value !== 'object') {
          console.error(errorMessage);
          return false;
        }

        const { startDate, endDate } = value;

        if (typeof startDate !== 'number' && startDate !== null) {
          console.error(errorMessage);
          return false;
        }

        if (typeof endDate !== 'number' && endDate !== null) {
          console.error(errorMessage);
          return false;
        }

        return true;
      }
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    customClass: {
      type: [String, Object],
      default: ''
    },
    customStyle: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // sizes
    sm: {
      type: Boolean,
      default: true
    },
    md: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: ''
    },
    datepickerForceSetValue: {
      type: Object
    }
  },
  data() {
    return {
      dateRange: this.value
    };
  },
  computed: {
    isErrorClass() {
      return {
        'is-error': this.errorText
      };
    }
  },
  methods: {
    handleFocus(event) {
      this.$emit('focus', event);
    }
  },
  watch: {
  }
};
</script>

<style lang="css" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
}
/* error tooltip */
.error-tooltip {
  display: block;
  font-size: 0.85rem;
  margin-top: 5px;
}

.is-error {
  color: var(--brand-danger);
}

/* label */
.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: var(--label-font-color);
  line-height: 1.5;
  font-size: 0.875rem;
}
</style>
<style>
/* чтобы достать во вложенный компонент */
.is-error input {
  border: 1px solid var(--brand-danger) !important;
}

.mx-input {
  background-color: inherit;
}
</style>
