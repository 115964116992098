<template>
  <div
    class="select-wrapper"
    :class="[sizeClass]">
    <label v-if="label" :for="id" class="label" :class="{'required-mark': required}">
      {{ label }}
    </label>
    <select
      class="w-select view-type-default"
      :name="name"
      :id="id"
      :class="[isErrorClass, customClass]"
      :disabled="disabled"
      :required="required"
      :value="value"
      @input="handleInput"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup="handleKeyup"
      @keydown="handleKeydown"
      v-bind="$attrs"
    >
      <option class="select__option" v-for="({data, display }) of options" :key="data" :value="data">
        {{ display }}
      </option>
    </select>
    <span v-show="errorText" class="error-tooltip is-error">
      {{ errorText }}
    </span>
  </div>
</template>

<script>
/**
* UI-компонент WSelect.
* Используется для выбора одного из предоставленных вариантов.
*
* @typedef {Object} WSelectProps
* @prop {string|number} value - Выбранное значение select.
* @prop {Array} options - Список опций для выбора.
* @prop {string} id  - ID.
* @prop {string} [name=''] - Имя select.
* @prop {string} [label=''] - Label для select.
* @prop {string|Object} [customClass=''] - Кастомные классы для select.
* @prop {Object} [customStyle={}] - Кастомные стили для select.
* @prop {boolean} [disabled=false] - Флаг для отключения select.
* @prop {boolean} [required=false] - Флаг, обязательно ли выбирать значение.
*
* Размеры:
* @prop {boolean} [sm=true] - Флаг для размера 'sm'.
* @prop {boolean} [md=false] - Флаг для размера 'md'.
* @prop {boolean} [lg=false] - Флаг для размера 'lg'.
*
* Валидация:
* @prop {string} [errorText=''] - Текст ошибки для textarea.
*
* События:
* @event input - Срабатывает при изменении значения в select.
* @event change - Срабатывает при изменении значения и потере фокуса в select.
* @event focus - Срабатывает при фокусировке на select.
* @event blur - Срабатывает при потере фокуса в select.
* @event keyup - Срабатывает при отпускании клавиши на select.
* @event keydown - Срабатывает при нажатии клавиши на select.
*
* Примеры использования компонента WSelect:
*
* @example
* Пример 1: Базовый select
* <WSelect v-model="selectedValue" :options="selectOptions" />
*
* @example
* Пример 2: Select с label
* <WSelect v-model="selectedValue" :options="selectOptions" label="Выберите что-то" />
*
* @example
* Пример 3: Отключенный select
* <WSelect v-model="selectedValue" :options="selectOptions" :disabled="true" />
*
* @example
* Пример 4: Select с обязательным выбором
* <WSelect v-model="selectedValue" :options="selectOptions" :required="true" />
*
* @example
* Пример 5: Select с размером 'lg'
* <WSelect v-model="selectedValue" :options="selectOptions" lg />
*/

export default {
  name: 'WSelect',
  inheritAttrs: false, // Отключаем наследование атрибутов по умолчанию
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: true
    },
    options: {
      type: Array,
      required: true,
      validator: options => {
        const errorMessage = 'prop options must be typed as { display: string | number, data: string | number }[]';

        if (!Array.isArray(options)) {
          console.error(errorMessage);
          return false;
        }

        if (!options.length) {
          console.error(errorMessage);
          return false;
        }

        const isValid = options.reduce((acc, curr) => {
          if (typeof curr !== 'object') {
            console.error(errorMessage);
            return false;
          }

          const { display, data } = curr;

          if (typeof display !== 'string' && typeof display !== 'number') {
            console.error(errorMessage);
            return false;
          }

          if (typeof data !== 'string' && typeof data !== 'number') {
            console.error(errorMessage);
            return false;
          }

          return true;
        }, true);

        return isValid;
      }
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    customClass: {
      type: [String, Object],
      default: ''
    },
    customStyle: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    // sizes
    sm: {
      type: Boolean,
      default: true
    },
    md: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isErrorClass() {
      return {
        'is-error': this.errorText
      };
    },
    sizeClass() {
      if (this.md) {
        return 'md';
      }

      if (this.lg) {
        return 'lg';
      }

      if (this.sm) {
        return 'sm';
      }

      return '';
    }
  },
  methods: {
    async handleInput(event) {
      const targetValue = event.target.value;
      this.$emit('input', targetValue);
    },
    async handleChange(event) {
      this.$emit('change', event);
    },
    handleFocus(event) {
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    },
    handleKeydown(event) {
      this.$emit('keydown', event);
    }
  }
};
</script>

<style lang="css" scoped>
/* root input class */
.w-select {
  display: block;
  width: 100%;
  line-height: 1.25;
  background-image: none;
  background-clip: padding-box;
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.w-select:focus {
  border-color: var(--brand-info);
}

.w-select:disabled {
  background-color: var(--gray-lighter) !important;
  cursor: not-allowed;
}

/* view type
  он один по умолчанию, поэтому только один класс
*/
.view-type-default {
  color: var(--font-color);
  background-color: transparent !important;
  border: 1px solid var(--border-color);
}

/* option */
.select__option {
  background-color: var(--select-option-bg-color) ;
}

/* label */
.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: var(--label-font-color);
  line-height: 1.5;
}

/* size classes */

.sm {
  font-size: 0.875rem;
}
.md {
  font-size: 0.875rem;
}
.lg {
  font-size: 1.25rem;
}

.sm .w-select {
  padding: 0.25rem 0.5rem;
}
.md .w-select {
  padding: 0.5rem 0.75rem;
}
.lg .w-select {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

/* error tooltip */
.error-tooltip {
  display: block;
  font-size: 0.85rem;
  margin-top: 5px;
}

.is-error {
  color: var(--brand-danger);
  border-color: var(--brand-danger)
}

.required-mark:after {
  content: " *";
  color: var(--brand-danger);
}
</style>
