import { LocaleMaster } from '@/utils';
import store from '@/store';
import { VLANHelpers } from '@/helpers/VLAN';

const isRULocale = store.getters['locale/isRuLocale'];

const getValidators = (i18nInstance) => ({
  uuid: {
    getMessage: (field) => 'The UUID does not match requirements',
    validate: (value) => {
      const uiidRegexp = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/i;
      return uiidRegexp.test(value);
    }
  },
  location: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Имя локации должно начинаться с буквы или числа и может содержать только буквы латинского алфавита и следующие специальные символы: _ -';
      }
      return 'The location name should start with a letter or a number and may contain letters of the Latin alphabet and the following special characters: _ -';
    },
    validate: (value) => {
      const strongRegex = new RegExp('^(([a-z,A-Z,0-9,_,-]+[/]?)*)$');
      return strongRegex.test(value);
    }
  },
  mac: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный MAC адрес';
      }
      return 'Wrong MAC address';
    },
    validate: (value) => {
      const macRegex = new RegExp(/^(([\da-f]{2}:){5}[\da-f]{2},?)+$/i);
      return macRegex.test(value);
    }
  },
  l2mac: {
    getMessage: (field) => 'Wrong MAC address',
    validate: (value) => {
      const macRegex = new RegExp(/^(!{0,1}\s{0,1}([\da-f]{2}:){5}[\da-f]{2},?)+$/i);
      return macRegex.test(value);
    }
  },
  ipv4: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный формат IPv4 адреса';
      }
      return 'Wrong IPv4 format';
    },
    validate: (value) => {
      const macRegex = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      return macRegex.test(value);
    }
  },
  ipv4WithSpecialOctetsRanges: {
    //
    // https://wimark.kaiten.ru/space/327612/card/37430746?focus=comment&focusId=48190817
    // Для IP требуется специальная валидация
    //
    // 1октет.2октет.3октет.4октет
    // 1 октет - три возможных интервала От 1 до 126, от 128 до 223 и от 240 до 254
    // 2 октет - от 0 до 254
    // 3 октет - от 0 до 254
    // 4 октет - от 1 до 254
    // И на всех октетах не может быть 255

    getMessage: (field) => {
      return i18nInstance.t('validation.ipv4WithSpecialOctetsRanges');
    },
    validate: (value) => {
      /**
       * Проверяет попадает ли октет в допустимй диапазон
       *
       * @param {number} octetNumber - номер октета (1октет.2октет.3октет.4октет)
       * @param {number} octet - сам октет
       *
       * @return {Boolean}
       */
      function isOctetInSpecialRange(octetNumber, octet) {
        const specialRangesForOctets = {
          // 1октет.2октет.3октет.4октет
          octet1: [{min: 1, max: 126}, {min: 128, max: 223}, {min: 240, max: 254}],
          octet2: [{min: 0, max: 254}],
          octet3: [{min: 0, max: 254}],
          octet4: [{min: 1, max: 254}]
        };

        let isOctetInRange = false;
        const specialRangesForCurrentOctet = specialRangesForOctets[`octet${octetNumber}`];
        for (const rangeForOctet of specialRangesForCurrentOctet) {
          if (rangeForOctet.min <= octet && octet <= rangeForOctet.max) isOctetInRange = true;
        }
        return isOctetInRange;
      }

      function isValidWithOctetRangesIPv4Address(ip) {
        const octets = ip.split('.');
        if (octets.length !== 4) return false;


        for (const octetIndexInArr in octets) {
          const octetNumber = parseInt(octetIndexInArr) + 1;
          const octet = octets[octetIndexInArr];

          if (octet.length > 1 && octet.startsWith('0')) return false;
          if (octet.length > 3) return false;
          if (!/^[0-9]+$/.test(octet)) return false;
          if (!isOctetInSpecialRange(octetNumber, parseInt(octet))) return false;
        }

        // На данныйй момент избыточная проверка, но оставил на случай если поменяются
        // правила в specialRangesForOctets, чтобы в любом случает не допускался broadcast адрес
        if (ip === '255.255.255.255') return false;

        return true;
      }

      return isValidWithOctetRangesIPv4Address(value);
    }
  },
  subnetMask: {
    getMessage: (field) => {
      return i18nInstance.t('validation.subnetMask');
    },
    validate: (value) => {
      const subnetMaskPattern = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/
      return subnetMaskPattern.test(value);

    }
  },
  macPrefix: {
    getMessage: (field) => {
      return i18nInstance.t('validation.macPrefix');
    },
    validate: (value) => {
      const mac = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$');
      return mac.test(value);
    }
  },
  ipv4orDomainName: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес или доменное имя';
      }
      return 'Wrong IP address or Domain Name';
    },
    validate: (value) => {
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      const domain = new RegExp(/^[\dA-Za-z][\dA-Za-z-]{1,61}[\dA-Za-z](?:\.[A-Za-z]{2,})+$/);
      return ipv4.test(value) || domain.test(value);
    }
  },
  ipv4orDomainNameForNtpServer: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес или доменное имя';
      }
      return 'Wrong IP address or Domain Name';
    },
    validate: (value) => {
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      const domain = new RegExp(/^[\dA-Za-z-]{0,61}[\dA-Za-z](?:\.[\dA-Za-z-]+)+$/);
      return ipv4.test(value) || domain.test(value);
    }
  },
  hexcolor: {
    getMessage: (field) => 'Wrong HEX color',
    validate: (value) => {
      const hexRegex = new RegExp(/^#([\dA-Fa-f]{6}|[\dA-Fa-f]{3})$/);
      return hexRegex.test(value);
    }
  },
  ipv4mask: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес';
      }
      return 'Wrong IP address';
    },
    validate: (value) => {
      const macRegex = new RegExp(
        /^(?=(?:\d+\.){3}\d+($|\/))(([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])\.?){4}(\/(\d|[12]\d|3[0-2]))?$/
      );
      return macRegex.test(value);
    }
  },
  url: {
    getMessage: (field) => 'Wrong Url address',
    validate: (value) => {
      const urlRegex = new RegExp(
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\w$&+,:;=\-]+@)?[\d-.A-Za-z]+|(?:www\.|[\w$&+,:;=\-]+@)[\d-.A-Za-z]+)((?:\/[\w%+./~\-]*)?\??[\w%&+.;=@\-]*#?[\w!./\\]*)?)/
      );
      return urlRegex.test(value);
    }
  },
  l2ipv4: {
    getMessage: (field) => 'Wrong IP address',
    validate: (value) => {
      const macRegex = new RegExp(
        /((^\s*(((!{0,1}\s{0,1})(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      return macRegex.test(value);
    }
  },
  l2port: {
    getMessage: (field) => 'Wrong Port address',
    validate: (value) => {
      if (typeof value === 'number' && value >= 0 && value < 65536) {
        const macRegex = new RegExp(/!{0,1}/);
        return macRegex.test(value);
      }
      return false;
    }
  },
  ssid: {
    getMessage: (field) => 'The SSID name may not be greater than 32 bytes',
    validate: (value) => {
      const getStringMemorySize = function (value) {
        let codePoint;
        let accum = 0;

        for (let stringIndex = 0, endOfString = value.length; stringIndex < endOfString; stringIndex++) {
          codePoint = value.charCodeAt(stringIndex);

          if (codePoint < 0x100) {
            accum += 1;
            continue;
          }

          if (codePoint < 0x10000) {
            accum += 2;
            continue;
          }

          if (codePoint < 0x1000000) {
            accum += 3;
          } else {
            accum += 4;
          }
        }
        return accum;
      };
      if (getStringMemorySize(value) > 32) {
        return false;
      }
      return true;
    }
  },
  hexhs: {
    getMessage: (field) => 'Wrong OI. Each OI is between 3 and 15 octets and is configured as a hexstring',
    validate: (value) => {
      const hexhsRegex = new RegExp(/^[\da-f]{6,30}$/);
      return hexhsRegex.test(value);
    }
  },
  pollquestion: {
    getMessage: (field) => i18nInstance.t('portal.pollquestionValidationError'),
    validate: (value) => {
      const questionRegex = new RegExp(/[$.]+/, 'gm');
      return !questionRegex.test(value);
    }
  },
  sms_code: {
    getMessage: (field) => i18nInstance.t('portal.smsCodeValidationError'),
    validate: (value) => {
      const reg = /.*{CODE}.*/g;
      return reg.test(value);
    }
  },
  socialAuthURL: {
    getMessage: (field) => i18nInstance.t('portal.socialURLValidationError'),
    validate: (value) => {
      const reg = /https?:\/\/(vk|facebook|instagram)\.com.*/g;
      return reg.test(value);
    }
  },
  date_format: {
    getMessage: (field) => i18nInstance.t('portal.correctDate'),
    validate: (value) => {
      const reg = /(?:\d{0,2}-){2}\d{4}$/g;
      return reg.test(value);
    }
  },
  custom_password: {
    getMessage: (field) => i18nInstance.t('login.customPasswordValidation'),
    validate: (value) => {
      const strongRegex = new RegExp('^(([a-z,A-Z,0-9,_,-,#,$,&,^,*,@,!])*)$');
      return strongRegex.test(value);
    }
  },
  user_password_in_wizard: {
    getMessage: (field) => i18nInstance.t('login.customPasswordValidation'),
    validate: (value) => {
      const strongRegex = new RegExp('^(([a-z,A-Z,0-9,_,\\-,#,$,&,^,*,@,!,%])*)$');
      return strongRegex.test(value);
    }
  },
  user_password: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Пароль может содержать только следующие специальные символы: _ - # $ & ^ * @ !';
      }
      return 'The password field may contain only the following special character: _ - # $ & ^ * @ !';
    },
    validate: (value) => {
      const strongRegex = new RegExp('^(([a-zA-Z0-9_\\-#$&^*@!])*)$');
      return strongRegex.test(value);
    }
  },
  userPasswordV2CheckValidCharacters: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Допустимы следующие символы: латинские буквы в верхнем и нижнем регистрах, цифры, специальные символы: _ - # $ & ^ * @ !';
      }
      return 'May contain only the following character: letters in upper and lower case, digits, special character: _ - # $ & ^ * @ !';
    },
    validate: (value) => {
      // console.log(value)
      const strongRegex = new RegExp('^(([a-zA-Z0-9_\\-#$&^*@!])*)$');
      return strongRegex.test(value);
    }
  },
  userPasswordV2CheckLength: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Длина пароля должна быть от 8 до 64 символов';
      }
      return 'The password length must be between 8 and 64 characters.';
    },
    validate: (value) => {
      // console.log(value)
      if (value.length >= 8 && value.length <= 64) {
        return true
      }
      return false;
    }
  },
  userPasswordV2CheckLengthMin: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Длина пароля должна быть не менее 8 символов';
      }
      return 'Password length must be at least 8 characters';
    },
    validate: (value) => {
      // console.log(value)
      if (value.length >= 8) {
        return true
      }
      return false;
    }
  },
  userPasswordV2CheckLengthMinForWLC: {
    getMessage: (field) => {
      if (isRULocale) {
        return 'Длина пароля должна быть не менее 6 символов';
      }
      return 'Password length must be at least 6 characters';
    },
    validate: (value) => {
      return value.length >= 6;
    }
  },
    userPasswordV2CheckLengthMax: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Длина пароля должна быть не более 64 символов';
      }
      return 'Password length must be no more than 64 characters';
    },
    validate: (value) => {
      // console.log(value)
      if (value.length <= 64) {
        return true
      }
      return false;
    }
  },
  userPasswordV2UpperCaseLetterIsContained: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Должна быть хотя бы одна латинская буква в верхнем регистре';
      }
      return 'Must have at least one uppercase letter';
    },
    validate: (value) => {
      // return /[A-Z]/.test(value);
      const strongRegex = new RegExp(/[A-Z]/);
      return strongRegex.test(value);

    }
  },
  userPasswordV2LowerCaseLetterIsContained: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Должна быть хотя бы одна латинская буква в нижнем регистре';
      }
      return 'Must have at least one lowercase letter';
    },
    validate: (value) => {
      // return /[A-Z]/.test(value);
      const strongRegex = new RegExp(/[a-z]/);
      return strongRegex.test(value);

    }
  },
  userPasswordV2DigitIsContained: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Должна быть хотя бы одна цифра';
      }
      return 'Must have at least one digit';
    },
    validate: (value) => {
      // return /[A-Z]/.test(value);
      const strongRegex = new RegExp(/[0-9]/);
      return strongRegex.test(value);

    }
  },
  emailForReports: {
    getMessage: (field) => i18nInstance.t('radar.wrongEmailMsg'),
    validate: (value) => {
      const strongRegex = new RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
      );
      return strongRegex.test(value);
    }
  },
  emailForUsers: {
    getMessage: (field) => i18nInstance.t('users.wrongEmailMsg'),
    validate: (value) => {
      const strongRegex = new RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
      );
      return strongRegex.test(value);
    }
  },
  inn: {
    getMessage: (field) => i18nInstance.t('location.innValidatorErrorMsg'),
    validate: (value) => {
      const strongRegex = new RegExp('^\\d+$');
      return strongRegex.test(value);
    }
  },
  ogrn: {
    getMessage: (field) => i18nInstance.t('location.ogrnValidatorErrorMsg'),
    validate: (value) => {
      const strongRegex = new RegExp('^\\d+$');
      return strongRegex.test(value);
    }
  },
  kpp: {
    getMessage: (field) => i18nInstance.t('location.kppValidatorErrorMsg'),
    validate: (value) => {
      const strongRegex = new RegExp('^\\d+$');
      return strongRegex.test(value);
    }
  },
  ipv4ForNatNetwork: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес';
      }
      return 'Wrong IP address';
    },
    validate: (value) => {
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      return ipv4.test(value);
    }
  },
  maskForNatNetwork: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверная маска для NAT сети';
      }
      return 'Wrong mask for the NAT network';
    },
    validate: (value) => {
      // console.log(value)

      // const netmask = new RegExp(
      //   /^(?=(?:\d+\.){3}\d+($|\/))(([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])\.?){4}(\/(\d|[12]\d|3[0-2]))?$/
      // );
      // const netmask = new RegExp(
      //   /^(254|252|248|240|224|192|128|0)\.0\.0\.0|255\.(254|252|248|240|224|192|128|0)\.0\.0|255\.255\.(254|252|248|240|224|192|128|0)\.0|255\.255\.255\.(255|254|252|248|240|224|192|128|0)$/
      // );
      const netmask = new RegExp(
        /^(?=(?:\d+\.){3}\d+($|\/))(([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])\.?){4}(\/(\d|[12]\d|3[0-2]))?$/
      );
      // console.log(netmask.test(value))
      return netmask.test(value);
    }
  },
  addressForEoGREServer: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес';
      }
      return 'Wrong IP address';
    },
    validate: (value) => {
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      // const domain = new RegExp(/^[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{1,})+$/);
      // return ipv4.test(value) || domain.test(value);
      return ipv4.test(value);
    }
  },

  ctn: {
    getMessage: (field) => {
      // if (LocaleMaster.isCachedLocaleRU()) {
      //   return 'CTN должно быть номером телефона (содержать только цифры, быть длиной 10 символов и иметь первым символом 9)';
      // }
      // return 'CTN value must be a phone number (contain only numbers, be 10 characters long, and have 9 as the first character)';
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'CTN должно быть номером телефона (10 цифр в формате 9ХХХХХХХХХ)';
      }
      return 'CTN value must be a phone number (10 digits in the format 9XXXXXXXXX)';
    },
    validate: (value) => {
      try {
        // проверяем чтобы номер был из 10 цифр с первой 9
        const tenDigitsRegex = new RegExp('^[0-9]{10}$');
        return tenDigitsRegex.test(value) && value[0] === '9';
      } catch (e) {
        return false;
      }
    }
  },

  signalStayValueForWlan: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Значение Минимального порога RSSI должно быть в диапазоне от -100 до -40 dbm (или 0 для отключения)';
      }
      return 'The Minimum RSSI Threshold value must be in the range of -100 to -40 dBm (or 0 to disable)';
    },
    validate: (value) => {
      // console.log(parseInt(value))
      // if (!parseInt(value)) { // так не пропускало 0 в качестве допустимого значения
      //   return false;
      // }
      if (isNaN(parseInt(value))) {
        return false;
      }
      if (parseInt(value) === 0 || value === '0') {
        return true;
      }
      if (parseInt(value) <= -40 && parseInt(value) >= -100) {
        return true;
      }
      return false;

      return false;
    }
  },

  signalStrikesValueForWlan: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Значение Количества проверок должно быть больше 0';
      }
      return 'The value of the Number of checks must be greater than 0';
    },
    validate: (value) => {
      // console.log(parseInt(value))
      // if (!parseInt(value)) { // так не пропускало 0 в качестве допустимого значения
      //   return false;
      // }
      if (isNaN(parseInt(value))) {
        return false;
      }
      if (parseInt(value) >= 0) {
        return true;
      }
      return false;

      return false;
    }
  },

  signalPollValueForWlan: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Значение Времени обновления должно быть больше 0';
      }
      return 'The value of the Poll Time must be greater than 0';
    },
    validate: (value) => {
      // console.log(parseInt(value))
      // if (!parseInt(value)) { // так не пропускало 0 в качестве допустимого значения
      //   return false;
      // }
      if (isNaN(parseInt(value))) {
        return false;
      }
      if (parseInt(value) >= 0) {
        return true;
      }
      return false;

      return false;
    }
  },
  signalDropReasonForWlan: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Код причины должен быть больше или равен 0';
      }
      return 'The Drop Reason code must be greater or equal 0';
    },
    validate: (value) => {
      // console.log(parseInt(value))
      // if (!parseInt(value)) { // так не пропускало 0 в качестве допустимого значения
      //   return false;
      // }
      if (isNaN(parseInt(value))) {
        return false;
      }
      if (parseInt(value) >= 0) {
        return true;
      }
      return false;

      return false;
    }
  },

  rssiThresholdValueForWlan: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Значение RSSI threshold должно быть в диапазоне от -90 до -60 dbm (или 0 для отключения)';
      }
      return 'The RSSI threshold value must be in the range of -90 to -60 dBm (or 0 to disable)';
    },
    validate: (value) => {
      // console.log(parseInt(value))
      // if (!parseInt(value)) { // так не пропускало 0 в качестве допустимого значения
      //   return false;
      // }
      if (isNaN(parseInt(value))) {
        return false;
      }
      if (parseInt(value) === 0 || value === '0') {
        return true;
      }
      if (parseInt(value) <= -60 && parseInt(value) >= -90) {
        return true;
      }
      return false;

      return false;
    }
  },
  radiusDaeClient: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес';
      }
      return 'Wrong IP address';
    },
    validate: (value) => {
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      // const domain = new RegExp(/^[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{1,})+$/);
      // return ipv4.test(value) || domain.test(value);
      return ipv4.test(value);
    }
  },
  portalAccessServersNasIP: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес';
      }
      return 'Wrong IP address';
    },
    validate: (value) => {
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      // const domain = new RegExp(/^[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{1,})+$/);
      // return ipv4.test(value) || domain.test(value);
      return ipv4.test(value);
    }
  },
  hoursMinutesForVisitorsFilters: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный формат времени. Введите время в формате ЧЧ:ММ';
      }
      return 'Not valid time format. Enter the time in HH:MM format';
    },
    validate: (value) => {
      // const hoursMinutes = new RegExp (
      //   '^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$'
      // );
      const hoursMinutes = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
      // const domain = new RegExp(/^[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{1,})+$/);
      // return ipv4.test(value) || domain.test(value);
      return hoursMinutes.test(value);
    }
  },
  portalAccessServersExternalIP: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес';
      }
      return 'Wrong IP address';
    },
    validate: (value) => {
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      // const domain = new RegExp(/^[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{1,})+$/);
      // return ipv4.test(value) || domain.test(value);
      return ipv4.test(value);
    }
  },
  snmpHostIP: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес';
      }
      return 'Wrong IP address';
    },
    validate: (value) => {
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      // const domain = new RegExp(/^[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{1,})+$/);
      // return ipv4.test(value) || domain.test(value);
      return ipv4.test(value);
    }
  },
  hoursMinutes: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный формат времени. Введите время в формате ЧЧ:ММ';
      }
      return 'Not valid time format. Enter the time in HH:MM format';
    },
    validate: (value) => {
      // const hoursMinutes = new RegExp (
      //   '^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$'
      // );
      const hoursMinutes = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
      // const domain = new RegExp(/^[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{1,})+$/);
      // return ipv4.test(value) || domain.test(value);
      return hoursMinutes.test(value);
    }
  },
  ditExportsFtpServerIPAndPort: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный Сервер FTP. Укажите адрес FTP сервера в формате ip:port (например 127.0.0.1:21)';
      }
      return 'Wrong FTP Server. Enter the FTP server address in the ip: port format (for example 127.0.0.1:21)';
    },
    validate: (value) => {
      try {
        // смотрим есть ли вообще во ввенденом символ ":"
        if (!value.includes(':')) {
          return false;
        }
        // пытаемся разбить на 2 строки по этому символу ":", если не получилось или получилось больше 2 строк
        // значит валидация не пройдена
        const inputedValuesAsArr = value.split(':');
        if (inputedValuesAsArr.length !== 2) {
          return false;
        }
        const ip = inputedValuesAsArr[0]; // подстрока введеная до символа ":"
        const port = inputedValuesAsArr[1]; // подстрока введеная после символа ":"
        // регуляока для ip
        const ipAddressRegExp = new RegExp(
          /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
        );
        // регулярка для порта
        const portRegExp = new RegExp(
          /^([1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/
        );
        // если обе части совпадают со своими правилами валидации - возвращается true иначе false
        return ipAddressRegExp.test(ip) && portRegExp.test(port);
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  },

  multiSelectWithInterfaceNotEmpty: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Поле Интерфейс обязательно для заполнения';
      }
      return 'The Interface field is required';
    },
    validate: (value) => {
      let isValid = true;
      if (!value || value === '' || (typeof value === 'object' && Object.keys(value).length === 0)) {
        isValid = false;
      }
      // console.log('isValid', isValid)
      return isValid;
    }
  },

  addressForFWUpgrade: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес или домен';
      }
      return 'Wrong IP address or domain';
    },
    validate: (value) => {
      const domain = new RegExp( '^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$')
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      return ipv4.test(value) || domain.test(value);
    }
  },
  domainOrIpForNTPServer: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес или домен';
      }
      return 'Wrong IP address or domain';
    },
    validate: (value) => {
      const domain = new RegExp( '^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z-]{2,})+$')
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      return ipv4.test(value) || domain.test(value);
    }
  },

  domainOrIpForExternalNTPServer: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP адрес или домен';
      }
      return 'Wrong IP address or domain';
    },
    validate: (value) => {
      const domain = new RegExp( '([a-z0-9|-]+\\.)*[a-z0-9|-]+\\.[a-z]+$')
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      return ipv4.test(value) || domain.test(value);
    }
  },

  ipOrMacListForReactiveLogs: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP или MAC';
      }
      return 'Wrong IP address or MAC';
    },
    validate: (value) => {
      const adressesAndMACs = value.split('\n');
      // console.log(adressesAndMACs)
      const mac = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$')
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      let isValid = true;
      const adressesAndMACsFilteredForEmptyStrings = adressesAndMACs.filter((line) => line !== '')
      // console.log(adressesAndMACsFilteredForEmptyStrings);
      adressesAndMACsFilteredForEmptyStrings.forEach((item) => {
        if (item.slice(-1) === ',') {
          isValid = false;
        }
        const itemMacsAndIPs = item.split(',')
        itemMacsAndIPs.forEach((item) => {
          const trimmedItemString = item.trim()
          if (!(mac.test(trimmedItemString) || ipv4.test(trimmedItemString))) {
            isValid = false;
          }
        })
      })
      // console.log('isValid', isValid)
      return isValid
    }
  },
  wmmAcCwFiled: {
    getMessage: (field) => {
      return i18nInstance.t('wmm.error.AcCwFiled');
    },
    validate: (value) => {
      const valueForCheck = parseInt(value);
      return [1, 3, 7, 15, 31, 63, 127, 255, 511, 1023, 2047, 4095, 8191, 16383, 32767].includes(valueForCheck);
    }
  },
  ipOrMacListForReactiveLogsForUpdateExisting: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Неверный IP или MAC. Или введено более 1 строки';
      }
      return 'Wrong IP address or MAC. Or more than 1 line';
    },
    validate: (value) => {
      const adressesAndMACs = value.split('\n');
      if (adressesAndMACs.length > 1) {
        return false
      }
      // console.log(adressesAndMACs)
      const mac = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$')
      const ipv4 = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      let isValid = true;
      const adressesAndMACsFilteredForEmptyStrings = adressesAndMACs.filter((line) => line !== '')
      // console.log(adressesAndMACsFilteredForEmptyStrings);
      adressesAndMACsFilteredForEmptyStrings.forEach((item) => {
        if (item.slice(-1) === ',') {
          isValid = false;
        }
        const itemMacsAndIPs = item.split(',')
        itemMacsAndIPs.forEach((item) => {
          const trimmedItemString = item.trim()
          if (!(mac.test(trimmedItemString) || ipv4.test(trimmedItemString))) {
            isValid = false;
          }
        })
      })
      // console.log('isValid', isValid)
      return isValid
    }
  },
  ipOrMacFilterMaxItemsPerStringForReactiveLogs: {
    getMessage: (field) => {
      if (LocaleMaster.isCachedLocaleRU()) {
        return 'Введено более 3 фильтров в строке';
      }
      return 'More than 3 filters entered per line';
    },
    validate: (value) => {
      const maxItemsInString = 3;
      const adressesAndMACs = value.split('\n');
      let isValid = true;
      const adressesAndMACsFilteredForEmptyStrings = adressesAndMACs.filter((line) => line !== '')
      // console.log(adressesAndMACsFilteredForEmptyStrings);
      adressesAndMACsFilteredForEmptyStrings.forEach((item) => {
        const itemMacsAndIPs = item.split(',')
        if (itemMacsAndIPs.length > maxItemsInString) {
          isValid = false;
        }
      })
      // console.log('isValid', isValid)
      return isValid;
    }
  },

  /**
   * В поля которые проверяются эти валидатором должно быть возможно вводить следующие данные
   * - Одиночное значение VLAN, например 2
   * - Несколько значений VLAN. В этом случае значения VLAN должны разделяться запятыми, например, 2,6,4,10
   * - Диапазон значений VLAN. Указание диапазона VLAN осуществляется с помощью тире, например, 2-8
   *
   */
  dynamicVLANFieldValidator: {
    getMessage: (field, args, data) => {
      const {failReason} = data;
      const {min, max} = VLANHelpers.getdynamicVLANBoundaries();
      if (failReason === 'NumberNotInRange') {
        return i18nInstance.t('vlan.errors.dynamicVLANNumberNotInRangeValidationError', {min, max});
      }
      if (failReason === 'NumberRepeated') {
        return i18nInstance.t('vlan.errors.dynamicVLANNumberRepeatedValidationError');
      }
      if (failReason === 'FirstNumberInRangeGreaterThenLast') {
        return i18nInstance.t('vlan.errors.dynamicVLANFirstNumberInRangeGreaterThenLastValidationError');
      }
      return i18nInstance.t('vlan.errors.dynamicVLANCommonValidationError', {min, max});
    },
    validate: (value) => {
      const inputedString = value;
      const inputedStringType = VLANHelpers.detectVlanFieldInputedType(inputedString);


      // Смотрим вариант ли это 1:
      // Одиночное значение VLAN, например 2
      // и проверяем
      if (inputedStringType === VLANHelpers.VLAN_FIELD_ENTERED_TYPES.NUMBER) {
        if (!VLANHelpers.isDynamicVLANNumberInBoundaries(inputedString)) {
          return {valid: false, data: {failReason: 'NumberNotInRange'}};
        }
        return true;
      }

      // Смотрим вариант ли это  2:
      // Несколько значений VLAN. В этом случае значения VLAN должны разделяться запятыми, например, 2,6,4,10
      // и проверяем
      if (inputedStringType === VLANHelpers.VLAN_FIELD_ENTERED_TYPES.COMMA_SEPARATED_NUMBERS) {
        // если действительно ввели строку из чисел через запятую, тогда проверяем каждое - влезают ли все числа в диапазон
        const inputedcommaSeparatedNumbers = inputedString.split(',');
        // eslint-disable-next-line no-restricted-syntax
        for (const number of inputedcommaSeparatedNumbers) {
          if (!VLANHelpers.isDynamicVLANNumberInBoundaries(number)) {
            // если какое-то не влезло то валидация не пройдена
            return {valid: false, data: {failReason: 'NumberNotInRange'}};
          }
        }
        // а теперь если все отдельные значения в диапазоне - проверим нет ли в введеных числах  одинаковых чисел
        const inputedcommaSeparatedNumbersUniq = Array.from(new Set(inputedcommaSeparatedNumbers));
        if (inputedcommaSeparatedNumbers.length !== inputedcommaSeparatedNumbersUniq.length) {
          // если есть повторы - то валидация не пройдена
          return {valid: false, data: {failReason: 'NumberRepeated'}};
        }
        return true;
      }

      // Смотрим вариант ли это  3:
      // Диапазон значений VLAN. Указание диапазона VLAN осуществляется с помощью тире, например, 2-8
      // и проверяем
      if (inputedStringType === VLANHelpers.VLAN_FIELD_ENTERED_TYPES.DASH_SEPARATED_RANGE) {
        // если действительно ввели диапазон из чисел через -, то разбиваем их в массив для дальнейших проверок
        const dashSeparetedNumberRange = inputedString.split('-');
        if (!VLANHelpers.isDynamicVLANNumberInBoundaries(dashSeparetedNumberRange[0]) ||
          !VLANHelpers.isDynamicVLANNumberInBoundaries(dashSeparetedNumberRange[1])) {
          // Если что-то из введеного не попадает в диапазон допустимых значений VLAN то валидация не пройдена
          return {valid: false, data: {failReason: 'NumberNotInRange'}};
        }
        if (parseInt(dashSeparetedNumberRange[0], 0) >= parseInt(dashSeparetedNumberRange[1], 0)) {
          // Если начло диапазона больше чем конец то тоже валидация не пройдена
          return {valid: false, data: {failReason: 'FirstNumberInRangeGreaterThenLast'}};
        }
        return true;
      }
      // если не распознали ни один из случаев то валидация также не пройдена
      return false;
    }
  }


});

export default getValidators;
